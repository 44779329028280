<template>
  <index saas />
</template>

<script>
import Index from '../index'

export default {
  name: 'GroupHotelForSaaS',
  components: { Index }
}
</script>
